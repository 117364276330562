import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import { title } from "@core/utils/filter";

// Releve
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function useModuleList() {
  // Use toast
  const toast = useToast();

  const moduleName = "releve";
  const APP_STORE_MODULE_NAME = "app-" + moduleName;
  const refDataListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: "id", label: "#", sortable: true },
    { key: "bank", label: "Banque", sortable: true },
    { key: "cheque", label: "N°Chèque", sortable: true },
    { key: "amount", label: "Montant", sortable: true },
    { key: "currency", label: "Devise", sortable: true },
    { key: "beneficiary_ref", label: "Beneficiaire", sortable: true }, 
    { key: "date", label: "Date", sortable: true },
    { key: "status", label: "Statut", sortable: true },
    { key: "actions" },
  ];
  const perPage = ref(5);
  const totalDatas = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [5, 10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(false);
  const attributeFilter = ref(null);
  const statusFilter = ref(null);

  const dataMeta = computed(() => {
    const localItemsCount = refDataListTable.value
      ? refDataListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalDatas.value,
    };
  });

  const refetchData = () => {
    refDataListTable.value.refresh();
  };

  watch(
    [currentPage, perPage, searchQuery, attributeFilter, statusFilter],
    () => {
      refetchData();
    }
  );

  const fetchModuleReleves = (ctx, callback) => {
    console.log("fech begin");
    store
      .dispatch(APP_STORE_MODULE_NAME + "/fetchModuleReleves", {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        attribute: attributeFilter.value,
        status: statusFilter.value,
      })
      .then((response) => {
        // const { data_list, total } = response.data
        callback(response.data);
        totalDatas.value = response.totalDatas;
      })
      .catch((error) => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Erreur chargement liste des données",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveDataTypeVariant = (type) => {
    if (type === "subscriber") return "primary";
    if (type === "manager") return "warning";
    if (type === "maintainer") return "success";
    if (type === "cachier") return "info";
    if (type === "admin") return "danger";
    return "primary";
  };

  const resolveDataTypeIcon = (type) => {
    if (type === "subscriber") return "DataIcon";
    if (type === "manager") return "SettingsIcon";
    if (type === "maintainer") return "DatabaseIcon";
    if (type === "cachier") return "Edit2Icon";
    if (type === "admin") return "ServerIcon";
    return "DataIcon";
  };

  const resolveDataStatusVariant = (status) => {
    if (status === "pending") return "warning";
    if (status === "active") return "success";
    if (status === "inactive") return "secondary";
    return "primary";
  };

  return {
    fetchModuleReleves,
    tableColumns,
    perPage,
    currentPage,
    totalDatas,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refDataListTable,

    resolveDataTypeVariant,
    resolveDataTypeIcon,
    resolveDataStatusVariant,
    refetchData,

    // Extra Filters
    attributeFilter,
    statusFilter,
  };
}
